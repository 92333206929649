import configData from "./config.json";
import React, { useEffect, useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import Cookies from 'universal-cookie';
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';

function Regulamin() {
    const apiUrl = configData.API_URL;

    const [show, setShow] = useState(false);

    const [appState, setAppState] = useState({
        loading: true,
        regulaminAccepted: false,
        tresc: null,
    });

    const cookies = new Cookies();

    const handleShow = () => {
        setShow(true);
        const url = apiUrl + `regulamin.php`;
        fetch(url)
            .then((res) => res.json())
            .then((tresc) => {
                setAppState({
                    loading: false,
                    tresc: tresc,
                    regulaminAccepted: cookies.get('regulaminAccepted-v2')
                });
            });
    };

    const handleClose = () => {
        setShow(false);
        cookies.set('regulaminAccepted-v2', true, { path: '/' })
    }

    const handleReject = () => {
        setShow(false);
        window.location.replace('https://zycienaniebiesko.com.pl/');
    }

    useEffect(() => {
        let regulaminCookie = cookies.get('regulaminAccepted-v2');
        if (!regulaminCookie) {
            handleShow();
        }
    }, []);

    return (<>
        <footer className="col-sm-12">
            <p>© 2022 — Typer na Niebiesko  | <a href="#" onClick={handleShow}>Regulamin</a></p>
        </footer>

        <Modal
            show={show}
            style={{ opacity: 1 }}
            className="custom-modal">
            <Modal.Header>
                <Modal.Title>Regulamin</Modal.Title>
            </Modal.Header>
            <Modal.Body className="custom-modal-body">
                <p style={{ whiteSpace: "pre-wrap" }}>
                    {appState.loading ? 'Ładowanie…' : <span dangerouslySetInnerHTML={{ __html: appState.tresc.content.trim()}} />}
                </p>
            </Modal.Body>
            <Modal.Footer>
                {appState.regulaminAccepted
                    ? <Button bsStyle="primary" onClick={handleClose}>OK</Button>
                    : <Button bsStyle="success" onClick={handleClose}>Akceptuj</Button>
                }
                {!appState.regulaminAccepted ? (<Button bsStyle="danger" onClick={handleReject}>Nie akceptuję</Button>) : ""}
            </Modal.Footer>
        </Modal>
    </>
    );
}
export default Regulamin;
