import configData from "./config.json";
import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';

function Login(props) {
    const apiUrl = configData.API_URL;

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const [registerUsername, setRegisterUsername] = useState("");
    const [email, setEmail] = useState("");
    const [registerPass, setRegisterPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");

    const [errorMsg, setErrorMsg] = useState("");
    const [passForgotMsg, setPassForgotMsg] = useState("Podaj adres email, aby zresetować hasło");
    const [registerErrorMsg, setRegisterErrorMsg] = useState("");

    const [show, setShow] = useState(false);
    const [isActiveLogin, setActiveLogin] = useState(true);
    const [isPassForgot, setPassForgot] = useState(false);

    const handleShowLogin = () => {
        setShow(true);
        setActiveLogin(true);
        setPassForgot(false);
    };

    const handleShowRegister = () => {
        setShow(true);
        setActiveLogin(false);
        setPassForgot(false);
    };

    const handleShowPassForgot = () => {
        setShow(true);
        setActiveLogin(false);
        setPassForgot(true);
    };

    const handleClose = () => {
        setShow(false);
        setErrorMsg('');
    }

    const handleLogin = async e => {
        e.preventDefault();
        const user = { username, password };

        const response = await axios.post(
            apiUrl + "login.php",
            user
        );
        if (response.data.success) {
            localStorage.setItem('loggedUser', response.data.login);
            localStorage.setItem('token', response.data.token);
            setErrorMsg('');
            props.user(response.data.login);
        }
        else {
            setErrorMsg(response.data.message);
            const div = document.getElementById('loginBox');
            div.classList.add("has-error");
        }
    }

    const handlePassForgot = async e => {
        e.preventDefault();
        const user = { email };

        const response = await axios.post(
            apiUrl + "resetPass.php",
            user
        );

        if (response.data.success) {
            setPassForgotMsg(response.data.message);
            setEmail('');
            const div = document.getElementById('emailForgotBox');
            div.classList.add("has-success");
        }
        else {
            setPassForgotMsg(response.data.message);
            const div = document.getElementById('emailForgotBox');
            div.classList.add("has-error");
        }
    }

    const handleRegister = async e => {
        e.preventDefault();
        if (!isPassEquals()) return;
        const registerUser = { registerUsername, registerPass, confirmPass, email };

        const response = await axios.post(
            apiUrl + "register.php",
            registerUser
        );

        if (response.data.success) {
            clearErr();
            setRegisterErrorMsg(response.data.message);
            setEmail('');
            const div = document.getElementById('registerBox');
            div.classList.add("has-success");
        }
        else {
            setRegisterErrorMsg(response.data.message);
            const div = document.getElementById('registerBox');
            div.classList.add("has-error");
        }
    }

    const clearErr = () => {
        setRegisterErrorMsg('');

        let div = document.getElementById('registerBox');
        div.classList.remove("has-error");
        div = document.getElementById('registerPassBox');
        div.classList.remove("has-error");
        div = document.getElementById('confirmPassBox');
        div.classList.remove("has-error");
    }

    const isPassEquals = () => {
        clearErr();
        if (registerPass !== confirmPass) {
            let div = document.getElementById('registerPassBox');
            div.classList.add("has-error");
            div = document.getElementById('confirmPassBox');
            div.classList.add("has-error");
            setRegisterErrorMsg("Hasła nie są równe!");
            return false;
        }
        return true;
    }

    return (<>
        <div className="col-sm-4">
            <div className="panel panel-primary">
                <div className="panel-heading">
                    Zabawa dostępna tylko po zalogowaniu
                </div>
                <div className="panel-body row">
                    <div className="col-sm-6">
                        <a href="#" className="btn btn-success btn-block" id="logowanieBtn" data-toggle="modal" data-target="#loginModal" onClick={handleShowLogin}>
                            <span className="glyphicon glyphicon-user"></span> Zaloguj
                        </a>
                    </div>
                    <div className="col-sm-6">
                        <a href="#" className="btn btn-primary btn-block" id="rejestracjaBtn" data-toggle="modal" data-target="#loginModal" onClick={handleShowRegister}>
                            <span className="glyphicon glyphicon-envelope"></span> Zarejestruj
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <Modal
            show={show}
            style={{ opacity: 1 }}
            onHide={handleClose}
            className="custom-modal">
            <Modal.Header>
                <div className="row">
                    <div className="col-xs-6" style={{ textAlign: 'center' }}>
                        <a href="#" className={isActiveLogin ? "panel-active" : "panel-inactive"} id="login-form-link" onClick={handleShowLogin}>Logowanie</a>
                    </div>
                    <div className="col-xs-6" style={{ textAlign: 'center' }}>
                        <a href="#" className={isActiveLogin || isPassForgot ? "panel-inactive" : "panel-active"} id="register-form-link" onClick={handleShowRegister}>Rejestracja</a>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body className="custom-modal-body">
                <div className="row">
                    <div className="col-lg-12">

                        <form id="login-form" role="form" onSubmit={handleLogin} className={isActiveLogin && !isPassForgot ? "form-active" : "form-inactive"} >
                            <div className="form-group" id="loginBox">
                                <span className='control-label'>{errorMsg}</span>
                                <input type="text" name="login" id="login" tabIndex="1" className="form-control" placeholder="Użytkownik" value={username}
                                    onChange={({ target }) => setUsername(target.value)} />
                            </div>
                            <div className="form-group">
                                <input type="password" name="loginPass" id="loginPass" tabIndex="2" className="form-control" placeholder="Hasło" value={password}
                                    onChange={({ target }) => setPassword(target.value)} />
                                <a href="#" className="ontrol-label" onClick={handleShowPassForgot}>Nie pamiętasz hasła?</a>
                            </div>
                            <div className="form-group text-center">
                                {/* <input type="checkbox" tabIndex="3" className="" value="true" name="RememberMe" id="RememberMe" />
                                <label for="rememberMe"> Zapamiętaj mnie</label> */}
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-sm-6 col-sm-offset-3">
                                        <input type="submit" name="login-submit" id="login-submit" tabIndex="4" className="form-control btn btn-success" value="Zaloguj" />
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form id="register-form" onSubmit={handleRegister} role="form" className={isActiveLogin || isPassForgot ? "form-inactive" : "form-active"}>
                            <div className="form-group" id="registerBox">
                                <span className='control-label'>{registerErrorMsg}</span>
                                <input type="text" name="registerUsername" id="registerUsername" tabIndex="1" className="form-control" placeholder="Użytkownik" value={registerUsername}
                                    onChange={({ target }) => setRegisterUsername(target.value)} required/>
                            </div>
                            <div className="form-group">
                                <input type="email" name="email" id="email" tabIndex="1" className="form-control" placeholder="Email" value={email}
                                    onChange={({ target }) => setEmail(target.value)} required/>
                            </div>
                            <div className="form-group" id="registerPassBox">
                                <input type="password" name="registerPass" id="registerPass" tabIndex="2" className="form-control" placeholder="Hasło" value={registerPass}
                                    onChange={({ target }) => setRegisterPass(target.value)} required/>
                            </div>
                            <div className="form-group" id="confirmPassBox">
                                <input type="password" name="confirmPass" id="confirmPass" tabIndex="2" className="form-control" placeholder="Powtórz hasło" value={confirmPass}
                                    onChange={({ target }) => setConfirmPass(target.value)} required/>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-sm-6 col-sm-offset-3">
                                        <input type="submit" name="register-submit" id="register-submit" tabIndex="4" className="form-control btn btn-primary" value="Zarejestruj" />
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form onSubmit={handlePassForgot} role="form" className={isPassForgot ? "form-active" : "form-inactive"}>
                            <div className="form-group" id="emailForgotBox">
                                <span className='control-label'>{passForgotMsg}</span>
                                <input type="email" name="email" tabIndex="1" className="form-control" placeholder="Email" value={email}
                                    onChange={({ target }) => setEmail(target.value)} required/>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-sm-6 col-sm-offset-3">
                                        <input type="submit" tabIndex="4" className="form-control btn btn-primary" value="Zresetuj hasło" />
                                    </div>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </Modal.Body>
        </Modal>
    </>
    );
}
export default Login;
