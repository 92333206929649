import configData from "../config.json";
import React, { useEffect, useState } from 'react';
import MyBets from '../MyBets';
import "bootstrap/dist/css/bootstrap.min.css";
import '../index.css';

function UserBets() {
    const apiUrl = configData.API_URL;

    const [users, setUsers] = useState([]);
    const [userSelected, setUserSelected] = useState('0');

    useEffect(() => {
        const loggedInUser = localStorage.getItem("loggedUser");
        const token = localStorage.getItem("token");
        const url = apiUrl + "users.php?user=" + loggedInUser + "&token=" + token + '&getActive=true';

        fetch(url)
            .then((res) => res.json())
            .then((json) => {
                setUsers(json);
            });
    }, []);

    return (<>
        <div class="col-sm-12">
            <div class="panel panel-primary">
                <div class="panel-heading">
                    Typy wybranego użytkownika
                </div>
                <div class="panel-body well">
                    <div class="col-sm-8">
                        <select class="form-control" data-val="true"
                            onChange={event => { setUserSelected(event.target.value) }}>
                            <option value="">--wybierz--</option>
                            {
                                users?.map(user =>
                                    <option key={user.userId} value={user.userId}>{user.name}</option>)
                            }
                        </select>
                    </div>
                </div>                
                <div className="panel-body">
                    <MyBets propsUserId={userSelected} />
                </div>
            </div>
        </div>
    </>
    );
}

export default UserBets;
