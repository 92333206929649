import configData from "../config.json";
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Alert } from 'react-bootstrap';
import Moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import '../index.css';

function MatchesAdm() {
    const apiUrl = configData.API_URL;

    const [newEventName, setNewEvent] = useState('');
    const [newEventDate, setNewEventDate] = useState(new Date());
    // const [teamsUsed, setTeamsUsed] = useState([]);
    const [teamsAvailable, setTeamsAvailable] = useState([]);
    const [teamsToEvent, setTeamsToEvent] = useState([]);

    const [players, setPlayers] = useState([]);

    const [eventsAvailable, setEventsAvailable] = useState([]);
    const [eventSelected, setEventSelected] = useState();
    const [eventDate, setEventDate] = useState();
    const [eventScorer, setEventScorer] = useState();
    const [eventMatches, setEventMatches] = useState([]);
    const [eventFinished, setEventFinished] = useState(false);

    const [eventSuccess, setEventSuccess] = useState();
    const [eventSuccessMessage, setEventSuccessMessage] = useState();

    useEffect(() => {
        loadTeams();
    }, []);

    const loadTeams = () => {
        const loggedInUser = localStorage.getItem("loggedUser");
        const token = localStorage.getItem("token");
        const url = apiUrl + "matches.php?user=" + loggedInUser + "&token=" + token;

        fetch(url)
            .then((res) => res.json())
            .then((json) => {
                setTeamsAvailable(json);

                let eventTeamsArray = new Array(Math.ceil(json.length / 2)).fill().map(x => { return { 'TeamHome': '', 'TeamAway': '' } });
                setTeamsToEvent(eventTeamsArray);
            });

        const url2 = apiUrl + "events.php?user=" + loggedInUser + "&token=" + token + "&action=getList";

        fetch(url2)
            .then((res) => res.json())
            .then((json) => {
                setEventsAvailable(json);
            });

        const playersUrl = apiUrl + "ruchPlayers.php";
        fetch(playersUrl)
            .then((res) => res.json())
            .then((json) => {
                setPlayers(json);
            });
    }

    const handleTeamHome = ((index, teamId) => {
        if (teamsToEvent.length >= index) {
            let item = teamsToEvent[index];
            item.TeamHome = teamId;
            teamsToEvent[index] = item;
            setTeamsToEvent(teamsToEvent);

            // let usedTeams = [...teamsToEvent.map(x => { return x.TeamHome }), ...teamsToEvent.map(x => { return x.TeamAway })];
            // setTeamsUsed(usedTeams);
        }
    });

    const handleTeamAway = ((index, teamId) => {
        if (teamsToEvent.length >= index) {
            let item = teamsToEvent[index];
            item.TeamAway = teamId;
            teamsToEvent[index] = item;
            setTeamsToEvent(teamsToEvent);
        }
    });

    const saveEvent = async e => {
        e.preventDefault();
        if (newEventName.length > 0) {
            const loggedInUser = localStorage.getItem("loggedUser");
            const tokenVal = localStorage.getItem("token");
            const response = await axios.post(
                apiUrl + "saveEvent.php",
                { matches: teamsToEvent, eventName: newEventName, startaDate: Moment(newEventDate).format("YYYY-MM-DDTHH:mm:ss"), user: loggedInUser, token: tokenVal }
            );
            setEventSuccess(response.data.success);
            setEventSuccessMessage(response.data.message);
            clearAlert();
            
            setTeamsAvailable(null);
            loadTeams();
            setNewEvent('');
            setNewEventDate(new Date());
        }
    };

    const updateEvent = async e => {
        e.preventDefault();
        if (eventSelected > 0 && eventMatches.length > 0) {
            const loggedInUser = localStorage.getItem("loggedUser");
            const tokenVal = localStorage.getItem("token");
            const response = await axios.post(
                apiUrl + "saveEvent.php",
                { matches: eventMatches, eventId: eventSelected, startaDate: Moment(eventDate).format("YYYY-MM-DDTHH:mm:ss"), scorer: eventScorer, finished: eventFinished, user: loggedInUser, token: tokenVal }
            );
            setEventSuccess(response.data.success);
            setEventSuccessMessage(response.data.message);
            clearAlert();
        }
    };

    const changeEvent = async e => {
        e.preventDefault();

        setEventMatches(null);
        setEventScorer('');
        setEventDate(null);
        setEventFinished(false);

        const loggedInUser = localStorage.getItem("loggedUser");
        const token = localStorage.getItem("token");

        const url = apiUrl + "events.php?user=" + loggedInUser + "&token=" + token + "&action=loadEvent&eventId=" + eventSelected;

        fetch(url)
            .then((res) => res.json())
            .then((json) => {
                setEventMatches(json.matches);
                setEventDate(new Date(json.startDate));
                setEventScorer(json.playerId);
                setEventFinished(json.finished);
            });
    }

    const handleGH = ((matchId, goals) => {
        const newState = eventMatches.map(obj => {
            if (obj.MatchId === matchId) {
                return { ...obj, GH: goals };
            }
            return obj;
        });

        setEventMatches(newState);
    });

    const handleGA = ((matchId, goals) => {
        const newState = eventMatches.map(obj => {
            if (obj.MatchId === matchId) {
                return { ...obj, GA: goals };
            }
            return obj;
        });

        setEventMatches(newState);
    });

    const clearAlert = () => {
        setTimeout(() => {
        setEventSuccess();
        setEventSuccessMessage();
        }, 3000);
    }

    return (<>
        {eventSuccess === true ? (<Alert bsStyle="success">{eventSuccessMessage}</Alert>) : ""}
        {eventSuccess === false ? (<Alert bsStyle="danger">{eventSuccessMessage}</Alert>) : ""}
        <form onSubmit={saveEvent}>
            <div class="col-sm-6">
                <div class="panel panel-primary">
                    <div class="panel-heading">
                        Wprowadź nową kolejkę
                    </div>
                    <div class="panel-body">
                        <table class="table table-condensed table-striped">
                            <thead>
                                <tr>
                                    <th class="text-center">
                                        <div class="col-sm-6">Gospodarz</div>
                                        <div class="col-sm-6">Gość</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>{!teamsAvailable ? '' : ([...Array(Math.ceil(teamsAvailable.length / 2))].map((e, idx) => (
                                <tr>
                                    <td>
                                        <div class="col-sm-1 field-label text-center">
                                            {idx + 1}.
                                        </div>
                                        <div class="col-sm-11">
                                            <div class="col-sm-6">
                                                <select class="form-control" data-val="true" key={(idx + "Home")}
                                                    onChange={(event) => { handleTeamHome(idx, event.target.value) }}>
                                                    <option value="">--wybierz--</option>
                                                    {
                                                        // teamsAvailable.filter(team => !teamsUsed.includes(team.teamId) || team.teamId == teamsToEvent[idx].TeamHome)
                                                        teamsAvailable.map(team =>
                                                            <option value={team.teamId}>{team.name}</option>)
                                                    }
                                                </select>
                                            </div>
                                            <div class="col-sm-6">
                                                <select class="form-control" data-val="true" key={idx + "Away"}
                                                    onChange={(event) => { handleTeamAway(idx, event.target.value) }}>
                                                    <option value="">--wybierz--</option>
                                                    {
                                                        teamsAvailable.map(team =>
                                                            <option value={team.teamId}>{team.name}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            )))}
                            </tbody>
                        </table>
                        <div class="col-sm-6 control-label">
                            Data zakończenia typowania
                        </div>
                        <div class="col-sm-6">
                            <DatePicker
                                showTimeSelect
                                dateFormat="yyyy-MM-dd HH:mm"
                                timeFormat="HH:mm"
                                selected={newEventDate}
                                onChange={(date) => setNewEventDate(date)}
                            />
                        </div>
                    </div>
                    <div class="panel-footer container-fluid">
                        <div class="col-sm-8">
                            <input class="form-control col-sm-12" data-val="true" value={newEventName}
                                onInput={e => { setNewEvent(e.target.value) }} required placeholder="Nazwa kolejki" type="text" />
                        </div>
                        <div class="col-sm-4">
                            <input type="submit" value="Zatwierdź kolejkę" class="btn btn-primary" />
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div class="col-sm-6">
            <div class="panel panel-primary">
                <div class="panel-heading">
                    Aktualizuj wyniki meczów
                </div>
                <div class="panel-body well">
                    <div class="col-sm-8">
                        <select class="form-control" data-val="true"
                            onChange={event => { setEventSelected(event.target.value) }}>
                            <option value="">--wybierz--</option>
                            {
                                eventsAvailable.map(event =>
                                    <option value={event.eventId}>{event.name}</option>)
                            }
                        </select>
                    </div>
                    <div class="col-sm-4">
                        <form onSubmit={changeEvent}>
                            <input type="submit" value="Zmień kolejkę" class="btn btn-default" />
                        </form>
                    </div>
                </div>
                {eventMatches ?
                    <><div class="panel-body">
                        <table class="table table-condensed table-striped">
                            <thead>
                                <tr>
                                    <th class="text-center">Gospodarz</th>
                                    <th colspan="2" class="text-center">Wynik</th>
                                    <th class="text-center">Gość</th>
                                </tr>
                            </thead>
                            <tbody>{!eventMatches ? "Brak danych" : eventMatches.map((bet) =>
                                <tr key={bet.MatchId}>
                                    <td className="field-label col-sm-5 text-center">
                                        {bet.TH}
                                    </td>
                                    <td className="col-md-1 ">
                                        <input className="form-control number-control" type="text" key={bet.MatchId + "GH"} value={bet.GH}
                                            onChange={(event) => { handleGH(bet.MatchId, event.target.value) }} />
                                    </td>
                                    <td className="col-sm-1 ">
                                        <input className="form-control number-control" type="text" key={bet.MatchId + "GA"} data-val="true" value={bet.GA}
                                            onChange={(event) => { handleGA(bet.MatchId, event.target.value) }} />
                                    </td>
                                    <td className="field-label col-sm-5 text-center">
                                        {bet.TA}
                                    </td>
                                </tr>)
                            }

                            </tbody>
                        </table>
                        <div className="row">
                            <span className="control-label col-sm-5">Strzelec bramki dla Ruchu:</span>
                            <div className="col-sm-7 ">
                                <select className="form-control" data-val="true" id="SelectedPlayerId" name="SelectedPlayerId" value={eventScorer}
                                    onChange={e => { setEventScorer(e.target.value) }} >
                                    <option value="">--wybierz--</option>
                                    {!players ? "" : (players.map((player) =>
                                        <option value={player.Id}>{player.Name}</option>))
                                    }
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-5 control-label">
                                Data zakończenia typowania
                            </div>
                            <div class="col-sm-1">
                            </div>
                            <div class="col-sm-6">
                                <DatePicker
                                    showTimeSelect
                                    dateFormat="yyyy-MM-dd HH:mm"
                                    timeFormat="HH:mm"
                                    selected={eventDate}
                                    onChange={date => setEventDate(date)}
                                />
                            </div>
                        </div>
                    </div>
                        <div class="panel-footer container-fluid">
                            <div class="checkbox col-sm-8">
                                <label>
                                    <input name="FinishEventModify"
                                        type="checkbox"
                                        checked={eventFinished} onChange={e => setEventFinished(!eventFinished)} /> Zakończ typowanie tej kolejki
                                </label>
                            </div>
                            <div class="col-sm-4">
                                <form onSubmit={updateEvent}>
                                    <input type="submit" value="Aktualizuj" class="btn btn-primary" />
                                </form>
                            </div>
                        </div>
                    </>
                    : ""
                }
            </div>
        </div>
    </>
    );
}

export default MatchesAdm;
