import configData from "./config.json";
import React, { useEffect, useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';

function Standings(props) {
    const apiUrl = configData.API_URL;
    const [isActive, setActive] = useState(true);
    const [appState, setAppState] = useState({
        loading: true,
        wyniki: null
    });

    const handleToggle = () => {
        setActive(!isActive);
    };

    useEffect(() => {
        setAppState({ loading: true, showMoreUsers: "hidden" });
        const url = apiUrl + `standings.php`;
        fetch(url)
            .then((res) => res.json())
            .then((json) => {
                setAppState({ loading: false, wyniki: json });
            });
    }, [setAppState]);

    return (<>
        <div className="col-sm-4">
            <div className="panel panel-primary">
                <div className="panel-heading">
                    Klasyfikacja
                </div>
                <div className="panel-body">
                    <table className="table table-condensed table-striped">
                        <thead>
                            <tr>
                                <th className="col-sm-4">Użytkownik</th>
                                <th className="text-center col-sm-2">Suma punktów</th>
                            </tr>
                        </thead>
                        <tbody>
                            {appState.loading ? 'Ładowanie…' : (!appState.wyniki.users
                                ? ""
                                : appState.wyniki.users
                                    .sort((a, b) => {

                                        if (a.points === b.points) {
                                            return a.user < b.user ? -1 : 1
                                        } else {
                                            return a.points < b.points ? 1 : -1
                                        }
                                    })
                                    .map((user, i) =>
                                        <tr className={i > 9 && isActive ? "hidden" : props.name && props.name.toLowerCase() === user.user.toLowerCase() ? "success" : null}>
                                            <td>
                                                <b>{i + 1}.</b> {user.user}
                                            </td>
                                            <td className="text-center">
                                                {user.points}
                                            </td>
                                        </tr>
                                    ))}
                        </tbody>
                    </table>
                    <button type="button" className=" col-sm-10 col-sm-offset-1 btn btn-xs btn-success" onClick={handleToggle}>Rozwiń</button>
                </div>
            </div>
        </div>
    </>
    );
}

export default Standings;
