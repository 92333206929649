import configData from "./config.json";
import React, { useEffect, useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';

function LastResults() {
    const apiUrl = configData.API_URL;

    const [appState, setAppState] = useState({
        loading: true,
        wyniki: null,
    });

    useEffect(() => {
        setAppState({ loading: true });
        const url = apiUrl + `lastEvent.php`;
        fetch(url)
            .then((res) => res.json())
            .then((json) => {
                setAppState({ loading: false, wyniki: json });
            });
    }, [setAppState]);

    return (<>
        <div className="col-sm-4">
            <div className="panel panel-primary">
                <div className="panel-heading">
                    Wyniki: <b>{appState.loading ? 'Ładowanie…' : appState.wyniki.event ? appState.wyniki.event : "brak ostatnich wyników do pokazania"}</b>
                </div>
                <div className="panel-body">
                    <table className="table table-condensed table-striped">
                        <thead>
                            <tr>
                                <th className="text-center">Gospodarz</th>
                                <th colSpan="2" className="text-center">Wynik</th>
                                <th className="text-center">Gość</th>
                            </tr>
                        </thead>
                        {appState.loading ? 'Ładowanie…' :
                            !appState.wyniki.matches
                                ? (<tr>
                                    <td className="field-label col-sm-5 text-center">
                                    </td>
                                    <td className="col-md-1 text-center">
                                    </td>
                                    <td className="col-sm-1 text-center">
                                    </td>
                                    <td className="field-label col-sm-5 text-center">
                                    </td>
                                </tr>)
                                : (<tbody>
                                    {appState.wyniki.matches.map((match) =>
                                        <tr>
                                            <td className="field-label col-sm-5 text-center">
                                                {match.TH}
                                            </td>
                                            <td className="col-md-1 text-center">
                                                <strong>{match.GH}</strong>
                                            </td>
                                            <td className="col-sm-1 text-center">
                                                <strong>{match.GA}</strong>
                                            </td>
                                            <td className="field-label col-sm-5 text-center">
                                                {match.TA}
                                            </td>
                                        </tr>)}
                                </tbody>)}
                    </table>
                    <div className="row">
                        <span className="control-label col-sm-5 text-right">Strzelec bramki dla Ruchu: </span><b>{appState.loading ? 'Ładowanie…' : appState.wyniki.scorer ? appState.wyniki.scorer : "brak strzelca"}</b>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
}

export default LastResults;
