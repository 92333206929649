import configData from "./config.json";
import React, { useEffect, useState } from 'react';
import { PanelGroup, Panel } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';

function MyBets({propsUserId}) {
    const apiUrl = configData.API_URL;

    const [betState, setState] = useState({
        loadingB: true,
        betsList: null
    });

    useEffect(() => {
        loadBets();
    }, [propsUserId]);

    const loadBets = () => {
        const loggedInUser = localStorage.getItem("loggedUser");
        const token = localStorage.getItem("token");
        let url = apiUrl + "myBets.php?user=" + loggedInUser + "&token=" + token;

        if (propsUserId) 
        {
            if (propsUserId == '0') return;
            url += '&userId=' + propsUserId;
        }

        fetch(url)
            .then((res) => res.json())
            .then((json) => {
                setState({ loadingB: false, betsList: json })
            });
    }

    return (<><PanelGroup accordion id="accordion-example">
        {betState.loadingB ? "" : (betState.betsList.events
            .map((event, index) =>
                <Panel eventKey={index}>
                    <Panel.Heading>
                        <Panel.Title toggle><b>{event.eventName}</b> - punkty zdobyte: {event.points}</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <table class="table table-condensed table-striped">
                            <thead>
                                <tr>
                                    <th class="text-center">Drużyny</th>
                                    <th class="text-center">Typ</th>
                                    <th class="text-center">Wynik</th>
                                    <th class="text-center">Zdobyte punkty</th>
                                </tr>
                            </thead>
                            <tbody>
                                {event.matches.map((event) =>
                                    <tr>
                                        <td class="field-label col-sm-5 text-center">
                                            {event.TH} : {event.TA}
                                        </td>
                                        <td class="col-md-1 text-center">
                                            <strong>{event.betGH} : {event.betGA}</strong>
                                        </td>
                                        <td class="col-sm-1 text-center">
                                            <strong>{event.GH} : {event.GA}</strong>
                                        </td>
                                        <td class="field-label col-sm-5 text-center">
                                            {event.pointsPart}
                                        </td>
                                    </tr>)}
                            </tbody>
                        </table>
                        <div>
                            Strzelec bramki dla Ruchu: <b>{event.playerName}</b> | Typowany strzelec: <b>{event.betPlayerName}</b> | Dodatkowe punkty: <b>{event.bonusPoints}</b>
                        </div>
                    </Panel.Body>
                </Panel>))}
    </PanelGroup>
    </>
    );
}

export default MyBets;
