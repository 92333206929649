import configData from "../config.json";
import React, { useEffect, useState, useId } from 'react';
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import '../index.css';

function TeamsAdm() {
    const apiUrl = configData.API_URL;

    const teamId = useId();
    const [newTeamName, setNewTeam] = useState('');

    const playerId = useId();
    const [newPlayerName, setNewPlayer] = useState('');

    const [teamsState, setTeamsState] = useState({
        loading: true,
        teamsList: null
    });

    const [playersState, setPlayersState] = useState({
        loading: true,
        playersList: null
    });

    useEffect(() => {
        loadTeams();
        loadPlayers();
    }, []);

    const loadTeams = () => {
        const loggedInUser = localStorage.getItem("loggedUser");
        const token = localStorage.getItem("token");
        const url = apiUrl + "teams.php?user=" + loggedInUser + "&token=" + token;

        fetch(url)
            .then((res) => res.json())
            .then((json) => {
                setTeamsState({ loading: false, teamsList: json })
            });
    };

    const loadPlayers = () => {
        const loggedInUser = localStorage.getItem("loggedUser");
        const token = localStorage.getItem("token");
        const url = apiUrl + "players.php?user=" + loggedInUser + "&token=" + token;

        fetch(url)
            .then((res) => res.json())
            .then((json) => {
                setPlayersState({ loading: false, playersList: json })
            });
    };

    const deletePlayer = async (event, playerId) => {
        event.preventDefault();
        const loggedInUser = localStorage.getItem("loggedUser");
        const tokenVal = localStorage.getItem("token");
        const response = await axios.post(
            apiUrl + "players.php",
            { actionName: "delete", id: playerId, user: loggedInUser, token: tokenVal }
        );
        if (response.data.success === true) {
            loadPlayers();
        }
    };

    const saveTeam = async e => {
        e.preventDefault();
        const loggedInUser = localStorage.getItem("loggedUser");
        const tokenVal = localStorage.getItem("token");
        const response = await axios.post(
            apiUrl + "teams.php",
            { actionName: "save", name: newTeamName, user: loggedInUser, token: tokenVal }
        );
        if (response.data.success === true) {
            loadTeams();
            setNewTeam('');
        }
    };

    const savePlayer = async e => {
        e.preventDefault();
        const loggedInUser = localStorage.getItem("loggedUser");
        const tokenVal = localStorage.getItem("token");
        const response = await axios.post(
            apiUrl + "players.php",
            { actionName: "save", name: newPlayerName, user: loggedInUser, token: tokenVal }
        );
        if (response.data.success === true) {
            loadPlayers();
            setNewPlayer('');
        }
    };

    const deleteTeam = async (event, teamId) => {
        event.preventDefault();
        const loggedInUser = localStorage.getItem("loggedUser");
        const tokenVal = localStorage.getItem("token");
        const response = await axios.post(
            apiUrl + "teams.php",
            { actionName: "delete", id: teamId, user: loggedInUser, token: tokenVal }
        );
        if (response.data.success === true) {
            loadTeams();
        }
    };

    return (<><div class="col-sm-6">
        <div class="panel panel-primary">
            <div class="panel-heading">
                Zarządzanie drużynami
            </div>
            <div class="panel-body">
                <table class="table table-striped">
                    <tbody><tr>
                        <th>
                            Nazwa drużyny
                        </th>
                        <th>
                            Akcja
                        </th>
                    </tr>
                        <tr>
                            <td>
                                <input class="form-control" id={teamId} value={newTeamName}
                                    onInput={e => { setNewTeam(e.target.value) }} required type="text" />
                            </td>
                            <td>
                                <form onSubmit={saveTeam}>
                                    <input type="submit" value="Dodaj" class="btn btn-primary" />
                                </form>
                            </td>
                        </tr>
                        {
                            teamsState.loading ? "Ładowanie..."
                                : (teamsState.teamsList.map((team, i) =>
                                    <tr>
                                        <td colspan="2">
                                            <a href="#" class="hover-btn" onClick={(e) => { deleteTeam(e, team.teamId) }}>
                                                <span class="glyphicon glyphicon-remove"></span></a>
                                            {team.name}
                                        </td>
                                    </tr>
                                )
                                )
                        }
                    </tbody></table>
            </div>
        </div>
    </div>
        <div class="col-sm-6">
            <div class="panel panel-primary">
                <div class="panel-heading">
                    Zarządzanie zawodnikami
                </div>
                <div class="panel-body">
                    <table class="table table-striped">
                        <tbody><tr>
                            <th>
                                Zawodnik Ruchu
                            </th>
                            <th>
                                Akcja
                            </th>
                        </tr>
                            <tr>
                                <td>
                                    <input class="form-control" id={playerId} value={newPlayerName}
                                        onInput={e => { setNewPlayer(e.target.value) }} required type="text" />
                                </td>
                                <td>
                                    <form onSubmit={savePlayer}>
                                        <input type="submit" value="Dodaj" class="btn btn-primary" />
                                    </form>
                                </td>
                            </tr>
                            {playersState.loading ? "Ładowanie..."
                                : (playersState.playersList.map((player) =>
                                    <tr>
                                        <td colspan="2">
                                            <a href="#" class="hover-btn" onClick={(e) => { deletePlayer(e, player.playerId) }}>
                                                <span class="glyphicon glyphicon-remove"></span></a>
                                            {player.name}
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody></table>
                </div>
            </div>
        </div>
    </>
    );
}

export default TeamsAdm;
