import configData from "./config.json";
import React, { useEffect, useState } from 'react';
import { Modal, Alert, Button } from 'react-bootstrap';
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';

function ActualBets() {
    const apiUrl = configData.API_URL;

    const [eventSuccess, setEventSuccess] = useState();
    const [eventSuccessMessage, setEventSuccessMessage] = useState();

    const [listPlayersState, setPlayersState] = useState({
        loadingP: true,
        playersList: null,
    });

    const [betState, setBetState] = useState({
        loadingB: true,
        betsList: null,
    });

    useEffect(() => {
        const loggedInUser = localStorage.getItem("loggedUser");
        const token = localStorage.getItem("token");
        if (!loggedInUser) {
        }

        setBetState({ loadingB: true });
        setPlayersState({ loadingP: true });
        const playersUrl = apiUrl + "ruchPlayers.php";
        const betsUrl = apiUrl + "actualBets.php?user=" + loggedInUser + "&token=" + token;

        fetch(playersUrl)
            .then((res) => res.json())
            .then((json) => {
                setPlayersState({ loadingP: false, playersList: json });
            });

        fetch(betsUrl)
            .then((res) => res.json())
            .then((json) => {
                setBetState({ loadingB: false, betsList: json });
            });

    }, []);

    const handleGH = ((index, goals) => {
        let list = betState.betsList;
        let item = list.bets[index];
        item.GH = goals;
        list.bets[index] = item;
        setBetState({ betsList: list });
    });

    const handleGA = ((index, goals) => {
        let list = betState.betsList;
        let item = list.bets[index];
        item.GA = goals;
        list.bets[index] = item;
        setBetState({ betsList: list });
    });

    const handlePlayer = ((playerId) => {
        let list = betState.betsList;
        list.playerId = playerId;
        setBetState({ betsList: list });
    });

    const handleBets = async e => {
        e.preventDefault();
        const loggedInUser = localStorage.getItem("loggedUser");
        const tokenVal = localStorage.getItem("token");
        const response = await axios.post(
            apiUrl + "saveBets.php",
            { bets: betState.betsList, user: loggedInUser, token: tokenVal }
        );
        setEventSuccess(response.data.success);
        setEventSuccessMessage(response.data.message);
        clearAlert();
    };

    const clearAlert = () => {
        setTimeout(() => {
            setEventSuccess();
            setEventSuccessMessage();
        }, 3000);
    }

    return (<>
        {eventSuccess === true ? (<Modal
            show={eventSuccess}
            container={this}
            aria-labelledby="contained-modal-title"
        >
            <Modal.Header><Alert bsStyle="success"><strong bsStyle="success">{eventSuccessMessage}</strong>
            </Alert>
            </Modal.Header>
            <Modal.Footer>
                <Button onClick={() => setEventSuccess()}>OK</Button>
            </Modal.Footer>
        </Modal>) : ""}
        <div className="col-sm-4">
            <div className="panel panel-primary">
                <div className="panel-heading">
                    Typowanie: <b>{betState.loadingB ? "" : betState.betsList.bets ? betState.betsList.eventName : "Brak kolejki do typowania"}</b>
                </div>
                {betState.betsList && betState.betsList.bets && listPlayersState.playersList &&
                    <form action="#" onSubmit={handleBets} className="form-horizontal" method="post">
                        <div className="panel-body">
                            <table className="table table-condensed table-striped">
                                <thead>
                                    <tr>
                                        <th className="text-center">Gospodarz</th>
                                        <th colSpan="2" className="text-center">Wynik</th>
                                        <th className="text-center">Gość</th>
                                    </tr>
                                </thead>
                                <tbody>{betState.betsList.bets && betState.betsList.bets
                                    .map((bet, index) =>
                                        <tr key={index}>
                                            <td className="field-label col-sm-5 text-center">
                                                {bet.TH}
                                            </td>
                                            <td className="col-md-1 ">
                                                <input className="form-control number-control" type="text" key={index + "GH"} value={bet.GH}
                                                    onChange={(event) => { handleGH(index, event.target.value) }} required />
                                            </td>
                                            <td className="col-sm-1 ">
                                                <input className="form-control number-control" type="text" key={index + "GA"} data-val="true" value={bet.GA}
                                                    onChange={(event) => { handleGA(index, event.target.value) }} required />
                                            </td>
                                            <td className="field-label col-sm-5 text-center">
                                                {bet.TA}
                                            </td>
                                        </tr>)
                                }

                                </tbody>
                            </table>
                            <div className="row">
                                <span className="control-label col-sm-5">Strzelec bramki dla Ruchu:</span>
                                <div className="col-sm-7 ">
                                    <select className="form-control" data-val="true" id="SelectedPlayerId" name="SelectedPlayerId" value={betState.loadingB ? "" : betState.betsList.playerId}
                                        onChange={({ target }) => { handlePlayer(target.value) }} required>
                                        <option value="">--wybierz--</option>
                                        {listPlayersState.loadingP ? "" : (listPlayersState.playersList
                                            .map((player) =>
                                                <option value={player.Id}>{player.Name}</option>))
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="panel-footer container-fluid">
                            <input type="submit" value="Wyślij typy" className="btn btn-block btn-primary" />
                        </div>
                    </form>
                }
            </div>
        </div>
    </>
    );
}

export default ActualBets;
