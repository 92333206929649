import configData from "../config.json";
import React, { useEffect, useState } from 'react';
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import '../index.css';

function UsersAdm() {
    const apiUrl = configData.API_URL;

    const [users, setUsers] = useState([]);

    useEffect(() => {
        loadUsers();
    }, []);

    const loadUsers = () => {
        const loggedInUser = localStorage.getItem("loggedUser");
        const token = localStorage.getItem("token");
        const url = apiUrl + "users.php?user=" + loggedInUser + "&token=" + token;

        fetch(url)
            .then((res) => res.json())
            .then((json) => {
                setUsers(json);
            });
    };

    return (<><div class="panel panel-default">
        <div class="panel-body">
            <table class="table table-condensed table-striped table-bordered">
                <thead>
                    <tr>
                        <th>Użytkownik</th>
                        <th>Email</th>
                        <th>Konto aktywne</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (users?.map((user, i) =>
                            <tr>
                                <td>
                                    <label class="control-label">{user.name}</label>
                                </td>
                                <td>{user.email}</td>
                                <td>{user.active == 1
                                    ? <span class="glyphicon glyphicon-ok text-success"></span>
                                    : <span class="glyphicon glyphicon-remove text-danger"></span>
                                }</td>
                            </tr>
                        )
                        )
                    }
                </tbody>
            </table>
        </div>
    </div>
    </>
    );
}

export default UsersAdm;
