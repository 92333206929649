import configData from "./config.json";
import React, { useState } from 'react';
import axios from "axios";

function PassChange() {
    const apiUrl = configData.API_URL;

    const [oldRegisterPass, setOldRegisterPass] = useState("");
    const [newRegisterPass, setNewRegisterPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");

    // TODO: rozwiązać inaczej temat błędów
    const [oldRegisterPassErr, setOldRegisterPassErr] = useState("");
    const [newRegisterPassErr, setNewRegisterPassErr] = useState("");
    const [confirmPassErr, setConfirmPassErr] = useState("");

    const handleChange = async e => {
        e.preventDefault();

        if (!isPassEquals()) return;

        const user = localStorage.getItem("loggedUser");
        const token = localStorage.getItem("token");
        const data = { user, token, oldRegisterPass, newRegisterPass }
        const response = await axios.post(
            apiUrl + "changePass.php",
            data
        );

        if (response.data.success) {
            clearErr();
            setOldRegisterPassErr('Hasło zmieniono poprawnie!');
            const div = document.getElementById('oldRegisterPassBox');
            div.classList.add("has-success");

            setOldRegisterPass('');
            setNewRegisterPass('');
            setConfirmPass('');
        }
        else {
            clearErr();

            setOldRegisterPassErr(response.data.message);
            const div = document.getElementById('oldRegisterPassBox');
            div.classList.add("has-error");
        }
    }

    const clearErr = () => {
        setOldRegisterPassErr('');
        setNewRegisterPassErr('');
        setConfirmPassErr('');

        let div = document.getElementById('oldRegisterPassBox');
        div.classList.remove("has-error");
        div = document.getElementById('newRegisterPassBox');
        div.classList.remove("has-error");
        div = document.getElementById('confirmPassBox');
        div.classList.remove("has-error");
    }

    const isPassEquals = () => {
        clearErr();
        if (newRegisterPass !== confirmPass) {
            let div = document.getElementById('newRegisterPassBox');
            div.classList.add("has-error");
            div = document.getElementById('confirmPassBox');
            div.classList.add("has-error");
            setConfirmPassErr("Hasła nie są równe!");
            return false;
        }
        return true;
    }

    return (<>
        <div class="well well-sm">
            <div class="panel-body">
                <div class="col-sm-8 col-sm-offset-2">
                    <form id="edit-user-form" onSubmit={handleChange} method="post" role="form">
                        <div class="form-group" id="oldRegisterPassBox">
                            <span className='control-label'>{oldRegisterPassErr}</span>
                            <input type="password" name="oldRegisterPass" id="oldRegisterPass" tabindex="2" class="form-control" placeholder="Aktualne hasło" value={oldRegisterPass}
                                onChange={({ target }) => setOldRegisterPass(target.value)} required />
                        </div>
                        <div class="form-group" id="newRegisterPassBox">
                            <span className='control-label'>{newRegisterPassErr}</span>
                            <input type="password" name="newRegisterPass" id="newRegisterPass" tabindex="2" class="form-control" placeholder="Nowe hasło" value={newRegisterPass}
                                onChange={({ target }) => setNewRegisterPass(target.value)} required />
                        </div>
                        <div class="form-group" id="confirmPassBox">
                            <span className='control-label'>{confirmPassErr}</span>
                            <input type="password" name="confirmPass" id="confirmPass" class="form-control" placeholder="Powtórz hasło" value={confirmPass}
                                onChange={({ target }) => setConfirmPass(target.value)} required />
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <div class="col-sm-6 col-sm-offset-3">
                                    <input type="submit" name="edit-submit" id="edit-submit" tabindex="4" class="form-control btn btn-primary" value="Zmień" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </>
    );
}
export default PassChange;
