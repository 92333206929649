import configData from "../config.json";
import React, { useEffect, useState } from 'react';
import { Modal, Alert, Button } from 'react-bootstrap';
import axios from "axios";
import '../index.css';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function App() {
    const apiUrl = configData.API_URL;
    const url = apiUrl + `regulamin.php`;
    const [text, setText] = useState({ text: "" });
    const [eventSuccess, setEventSuccess] = useState();

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        fetch(url)
            .then((res) => res.json())
            .then((tresc) => {
                setText(tresc.content);
            });
    };

    const handleChange = (value) => {
        setText(value);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        
        const loggedInUser = localStorage.getItem("loggedUser");
        const tokenVal = localStorage.getItem("token");
        const response = await axios.post(
            url,
            { actionName: "update", text: text, user: loggedInUser, token: tokenVal }
        );

        if (response.data.success === true) {
            setEventSuccess(response.data.success);
            setTimeout(() => {
                setEventSuccess();
            }, 3000);
        }
    }; 

    const modules = {
        toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ color: [] }], // dropdown with defaults from theme
            [{ align: [] }]
        ]
    };

    const formats = [
        "header",
        "bold",
        "blockquote",
        "bullet",
        "italic",
        "strike",
        "list",
        "underline",
        "indent",
        "link",
        "code-block",
        "background",
        "size",
        "color",
        "image",
        "font",
        "script",
        "align",
        "direction",
        "video"
    ];
    return (
        <>
        <div>
            <h3>Regulamin</h3>

            <form onSubmit={onSubmit}>
                <ReactQuill
                    modules={modules}
                    formats={formats}
                    value={text}
                    onChange={handleChange}
                />
                <Button type="submit">Zatwierdź</Button>
            </form>
        </div>

        <Modal
            show={eventSuccess}
            container={this}
            aria-labelledby="contained-modal-title"
        >
            <Modal.Header><Alert bsStyle="success"><strong bsStyle="success">Regulamin został zaktualizowany</strong>
            </Alert>
            </Modal.Header>
            <Modal.Footer>
                <Button onClick={() => setEventSuccess()}>OK</Button>
            </Modal.Footer>
        </Modal>
  </>
    );
}
