import configData from "./config.json";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from 'react';
import { Nav, NavItem, NavDropdown, Navbar, MenuItem } from "react-bootstrap";
import LastResults from './LastResults';
import Regulamin from './Regulamin';
import Standings from './Standings';
import Login from './Login';
import ActualBets from './ActualBets';
import MyBets from './MyBets';
import PassChange from './PassChange';
import TeamsAdm from './administration/TeamsAdm';
import MatchesAdm from "./administration/MatchesAdm";
import RegulaminAdm from "./administration/RegulaminAdm";
import UsersAdm from "./administration/UsersAdm";
import UserBets from "./administration/UserBets";

function App() {
    const apiUrl = configData.API_URL;

    const [user, setUser] = useState();

    const [key, setKey] = useState("home");

    const [appState, setAppState] = useState({
        loading: true,
        regulaminTresc: null,
    });

    useEffect(() => {
        const loggedInUser = localStorage.getItem("loggedUser");
        if (loggedInUser) {
            const token = localStorage.getItem("token");
            if (token) {
                setUser(loggedInUser);
            }
            else {
                localStorage.clear();
                window.location.reload(false);
            }
        }
    }, []);

    const handleLogout = () => {
        const loggedInUser = localStorage.getItem("loggedUser");
        const token = localStorage.getItem("token");
        const url = apiUrl + "logout.php?user=" + loggedInUser + "&token=" + token;

        fetch(url)
            .then((res) => res.json())
            .then(() => {
                localStorage.clear();
                window.location.reload(false);
            });
    };

    const handleShowRegulamin = () => {
        const url = apiUrl + `regulamin.php`;
        fetch(url)
            .then((res) => res.json())
            .then((tresc) => {
                setAppState({ regulaminTresc: tresc.content });
            });
    };

    return (

        <div className="container">
            <Navbar collapseOnSelect onSelect={(k) => setKey(k)}>
                <Navbar.Header>
                    <Navbar.Brand>
                        <a href="#" onClick={() => setKey("home")} >Typer na Niebiesko</a>
                    </Navbar.Brand>
                    <Navbar.Toggle />
                </Navbar.Header>
                {user &&
                    <Navbar.Collapse>
                        <Nav>
                            <NavItem onClick={handleShowRegulamin} eventKey={"regulamin"} href="#">
                                Regulamin
                            </NavItem>
                            {user !== 'admin' &&
                                <NavItem eventKey={"my-bets"} href="#">
                                    Moje typowania
                                </NavItem>
                            }
                            <NavItem eventKey={"change-pass"} href="#">
                                Zmiana hasła
                            </NavItem>
                            {user === 'admin' &&
                                <NavDropdown eventKey={3} title="Administracja">
                                    <MenuItem eventKey={"teams-adm"}>Drużyny</MenuItem>
                                    <MenuItem eventKey={"matches-adm"}>Mecze</MenuItem>
                                    <MenuItem eventKey={"user-bets"}>Typy użytkowników</MenuItem>
                                    <MenuItem divider />
                                    <MenuItem eventKey={"users-adm"}>Użytkownicy</MenuItem>
                                    <MenuItem divider />
                                    <MenuItem eventKey={"regulamin-adm"}>Regulamin</MenuItem>
                                </NavDropdown>
                            }
                        </Nav>
                        <Nav pullRight style={{ marginRight: 0 }}>
                            <NavItem onClick={handleLogout} href="#" >
                                Wyloguj
                            </NavItem>
                        </Nav>
                    </Navbar.Collapse>
                }
            </Navbar>

            <div className="row">
                {key === "home" && user && <ActualBets />}
                {key === "home" && !user && <Login user={setUser} />}
                {key === "home" && <LastResults />}
                {key === "home" && <Standings name={user} />}

                {key === "regulamin" &&
                    <div className="panel panel-primary">
                        <div className="panel-heading">
                            <strong>Regulamin:</strong>
                        </div>
                        <div className="panel-body">
                            <span style={{ whiteSpace: "pre-wrap" }}>{appState.loading ? 'Ładowanie…' : <span dangerouslySetInnerHTML={{ __html: appState.regulaminTresc.trim() }} />}</span>
                        </div>
                    </div>
                }
                {key === "my-bets" && <MyBets />}
                {key === "change-pass" && <PassChange />}
                {key === "teams-adm" && <TeamsAdm />}
                {key === "matches-adm" && <MatchesAdm />}
                {key === "user-bets" && <UserBets />}
                {key === "users-adm" && <UsersAdm />}
                {key === "regulamin-adm" && <RegulaminAdm />}

            </div>

            <hr />

            <Regulamin />
        </div>
    );
}

export default App;
